<template>
  <div class="password">
    <p class="title">密码登录</p>
    <el-form ref="form" :model="loginForm" label-width="40px" :rules="rules">
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          size="large"
          auto-complete="off"
          placeholder="请输入注册使用的手机号"
          @keyup.enter.native="login('form')"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          :type="passwordType"
          v-model="loginForm.password"
          size="large"
          auto-complete="off"
          maxlength="18"
          placeholder="请输入密码"
          @keyup.enter.native="login('form')"
        >
          <i
            slot="suffix"
            class="el-icon-view el-input__icon"
            @click="showPassword"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-row :span="24">
          <el-col :span="16">
            <el-input
              :maxlength="code.len"
              v-model="loginForm.code"
              size="large"
              auto-complete="off"
              placeholder="请输入验证码"
              @keyup.enter.native="login('form')"
            >
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="login-code">
              <span
                v-if="code.type == 'text'"
                class="login-code-img"
                @click="refreshCode"
                >{{ code.value }}</span
              >
              <img
                v-else
                :src="code.src"
                class="login-code-img"
                @click="refreshCode"
              />
              <!-- <i class="icon-shuaxin login-code-icon" @click="refreshCode"></i> -->
            </div>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="submitBtn" @click="login('form')"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
    <span class="forget" @click="forget">忘记密码</span>
  </div>
</template>
<script>
import { randomLenNum } from "@/util/util";
export default {
  name: "Password",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        code: "",
        redomStr: "",
      },
      code: {
        // src: "/code",
        src: "/api/code",
        value: "",
        len: 4,
        type: "image",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      passwordType: "password",
      dialogVisible: false,
      qrCode: {},
      timer: undefined,
    };
  },
  created() {
    clearInterval(this.timer);
    this.refreshCode();
  },
  mounted() {},
  computed: {},
  methods: {
    //登录
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("login", this.loginForm);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  text-align: right;
  .title {
    text-align: center;
    margin: 0;
    font-size: 18px;
  }
}
 .el-form-item__label {
  display: none;
}
 .el-form {
  margin-top: 20px;
}
 .el-form-item {
  margin-bottom: 30px;
}
 .el-form-item__content {
  margin-left: 0 !important;
}
.submitBtn {
  background-color: #53b7d7 !important;
  border-color: #53b7d7 !important;
  letter-spacing: 5px;
  width: 100%;
}
.otherLogin {
  float: left;
  width: 25px;
  cursor: pointer;
}
.forget {
  font-size: 13px;
  color: gray;
  cursor: pointer;
}
 .el-dialog__body {
  text-align: center !important;
}
</style>