<template>
	<div class="page_bg" style="height: 100vh; ">
		<div class="loginBox">
			<el-form ref="form" class="loginBoxCont" :model="loginForm" label-width="0px" :rules="rules">
				<el-form-item>
					<p style="text-align: center; font-size: 20px">密码登录企业认证</p>
				</el-form-item>
				<el-form-item prop="username">
					<el-input v-model="loginForm.username" size="large" auto-complete="off" placeholder="请输入注册使用的手机号"
						@keyup.enter.native="login('form')">
					</el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input :type="passwordType" v-model="loginForm.password" size="large" auto-complete="off"
						maxlength="18" placeholder="请输入密码" @keyup.enter.native="login('form')">
						<i slot="suffix" class="el-icon-view el-input__icon" @click="showPassword" />
					</el-input>
				</el-form-item>
				<el-form-item prop="code">
					<el-row :span="24">
						<el-col :span="16">
							<el-input :maxlength="code.len" v-model="loginForm.code" size="large" auto-complete="off"
								placeholder="请输入验证码" @keyup.enter.native="login('form')">
							</el-input>
						</el-col>
						<el-col :span="8">
							<div class="login-code">
								<span v-if="code.type == 'text'" class="login-code-img"
									@click="refreshCode">{{ code.value }}</span>
								<img v-else :src="code.src" class="login-code-img" @click="refreshCode" />
								<!-- <i class="icon-shuaxin login-code-icon" @click="refreshCode"></i> -->
							</div>
						</el-col>
					</el-row>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" class="submitBtn" style="width: 350px;" @click="handleLogin()">登录</el-button>
				</el-form-item>
			</el-form>
			<!-- <span class="forget" @click="forget">忘记密码</span> -->
		</div>
	</div>
</template>
<script>
	import {
		randomLenNum
	} from "@/util/util";
	import Password from "@/components/password";
	import JsEncrypt from 'jsencrypt'
	export default {
		data() {
			return {
				loginForm: {
					username: "",
					password: "",
					code: "",
				},
				code: {
					src: "/code",
					// src: "/api/code",
					value: "",
					len: 4,
					type: "image",
				},
				rules: {
					username: [{
						required: true,
						message: "请输入用户名",
						trigger: "blur"
					}, ],
					password: [{
						required: true,
						message: "请输入密码",
						trigger: "blur"
					}],
					code: [{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					}],
				},
				passwordType: "password",
				dialogVisible: false,
				qrCode: {},
				timer: undefined,
			};
		},
		created() {
			this.refreshCode();
		},
		methods: {
			//登录
			handleLogin() {
				if(!this.loginForm.username) {
					this.$message.error('请输入正确的账号');
					return
				}
				if(!this.loginForm.password) {
					this.$message.error('请输入密码');
					return
				}
				if(!this.loginForm.code) {
					this.$message.error('请输入验证码');
					return
				}
				let params = {
					...this.loginForm,
				}
				this.$http.getPublicKey().then(res => {
					if (res.code == 0) {
						const RSA = new JsEncrypt()
						RSA.setPublicKey(res.data)
						params.password = RSA.encrypt(params.password)
						params.publicKey = res.data
						params.loginWithCode = true
						params.grant_type = 'password'
						this.$http.loginByUsername(params)
							.then((res) => {
								console.log(res.access_token)
								window.localStorage.setItem("token", res.access_token);
								this.$router.push("/home");
							})
							.catch((err) => {
								this.$message.error(err.msg);
								this.refreshCode();
							});
					}
				})

			},
			refreshCode() {
				this.loginForm.code = "";
				this.loginForm.randomStr = randomLenNum(this.code.len, true);
				this.code.type === "text" ?
					(this.code.value = randomLenNum(this.code.len)) :
					(this.code.src = `/api/code?randomStr=${this.loginForm.randomStr}`);
			},
			// 切换密码可显
			showPassword() {
				this.passwordType == "" ?
					(this.passwordType = "password") :
					(this.passwordType = "");
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/styles/login";

	.toHome {
		cursor: pointer;
	}

	.back {
		position: absolute;
		right: 50px;
		top: 50px;
		z-index: 999;
		cursor: pointer;
	}

	.page_bg {
		background-image: url('../../assets/loginBack.png');
	}

	.loginBox {
		background-color: white;
		border-radius: 10px;
		width: 400px;
		height: 350px;
		position: absolute;
		bottom: 10px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		.goRegister {
			width: 100%;
			height: 60px;
			line-height: 60px;
			position: absolute;
			left: 0;
			bottom: 0;
			background-color: rgb(246, 246, 246);
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;

			p {
				letter-spacing: 1px;
				margin: 0;

				.registerBtn {
					margin-left: 5px;
					color: #53b7d7;
					cursor: pointer;
				}
			}
		}

		.loginBoxCont {
			width:350px;
			position: absolute;
			bottom: 10px;
			left: 50%;
			top: 40%;
			transform: translate(-50%, -50%);
		}
	}
</style>
